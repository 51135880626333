<template>
<div>
  <h3>Debug</h3>
  Url: <input v-model="url"/>
  <button class="btn btn-sm btn-primary m-1" @click="gotoUrl">Go</button><br/>
  <br/>
  <button class="btn btn-sm btn-primary m-1" @click="clearUser">Clear UID</button>
  <div v-for="u in urls" v-bind:key="u">
    <button class="btn btn-sm btn-primary m-1" @click="gotoUrl(u)">{{ u }}</button>
  </div>
  <h3>Scrolling test</h3>
  <div style="width:600px;height:200px" class="alert-info">
    Lol
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      urls: [
        "/guest?uid=QdzWh7V5NAUTfIUGvvuf",
        "/guest?uid=hIVovKjI3OOv3SM1bGGP&room=tOeLviulNXEnmjrOMvqr&debug=true",
        "/tests/studio2",
        "/tests/test-composition",
        "/tests/test-composer",
      ]
    }
  },
  methods: {
    gotoUrl(url) {
      window.location.href = url ? url : this.url;
    },
    clearUser() {
      this.$router.push("/guest?uid=0");
    }
  }
}
</script>

<style>

</style>